<template>
  <div class="website-config">
    <DgPanel>
      <form @submit.prevent="submit" v-if="isFormShown">
        <div class="website-config__header">
          {{ $t("dg_suite.consent_management.configuration.admin_access.heading") }}
        </div>
        <div class="website-config__body">
          <div class="row">
            <div class="col-6">
              <DgInput
                inputType="large"
                :attrs="{ rows: 10, value: website.permitted_user_emails.join('\n') }"
                :label="$t('dg_suite.consent_management.configuration.admin_access.permitted_user_emails.label')"
                @input="onPermittedUserEmailsChange($event)"
              />
              <p>{{ $t("dg_suite.consent_management.configuration.admin_access.permitted_user_emails.hint") }}</p>
              <DgButton :disabled="isButtonDisabled" :loading="formSubmitting" nativeType="submit">{{
                $t("dg_suite.consent_management.configuration.save")
              }}</DgButton>
            </div>
            <div class="col-6">
              <div v-html="markdown($t('dg_suite.consent_management.configuration.admin_access.description'))" />
              <div v-html="$t('dg_suite.consent_management.configuration.admin_access.configuration_documentation')" />
              <div v-if="isSSOEnabled">
                <div v-html="markdown($t('dg_suite.consent_management.configuration.admin_access.usage_with_sso'))" />
                <div class="button-group">
                  <DgButtonHighlightable
                    type="button"
                    variant-style="secondary"
                    animation-type="squish"
                    @click="accessAdminArea"
                    :disabled="formSubmitting"
                    :highlight="shouldHighlightAdminButton"
                  >
                    {{ $t("dg_suite.consent_management.configuration.admin_access.access_admin_area") }}
                  </DgButtonHighlightable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <DgLoadingIndicator
        :is-loading="isLoading || isSaving"
        :label="$t(`dg_suite.consent_management.configuration.${isSaving ? 'saving' : 'loading'}_label`)"
      />
    </DgPanel>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Structural, Basic } from "@/dgui-customer-components/";

import sharedFunctions from "../../mixins/shared-functions";
import refreshCurrentWebsite from "../../mixins/refresh-current-website";
import DgButtonHighlightable from "../../components/CookieManager/DgButtonHighlightable.vue";

export default {
  name: "CookieManagerAccess",
  mixins: [sharedFunctions, refreshCurrentWebsite],
  components: {
    DgPanel: Structural.DgPanel,
    DgLoadingIndicator: Basic.DgLoadingIndicator,
    DgButtonHighlightable,
  },
  data() {
    return {
      isLoading: !this.currentWebsite,
      isSaving: false,
      formSubmitting: false,
      backendError: null,
      wasFormChanged: false,
      websiteDefaultAttributes: {
        permitted_user_emails: [],
      },
      shouldHighlightAdminButton: false,
      website: {},
    };
  },
  computed: {
    ...mapState({
      currentWebsite: state => state.dg_suite.currentWebsite || {},
    }),
    isFormShown() {
      return !this.isSaving && !this.isLoading && !this.backendError;
    },
    isButtonDisabled() {
      return !this.wasFormChanged;
    },
    adminAreaLink() {
      const { id: customerId } = this.$store.state.user.companyData;
      const { id: websiteId } = this.website;

      return `/redirect/api/v2/customers/${customerId}/usercentrics/websites/${websiteId}/admin`;
    },
    isSSOEnabled() {
      return this.$store.getters.isFeatureEnabled("cookie_manager_sso");
    },
  },
  methods: {
    ...mapActions(["fetchWebsites", "fetchWebsite", "saveWebsite"]),
    onPermittedUserEmailsChange(event) {
      this.wasFormChanged = true;
      const value = event.target.value;
      const emailAddresses = value.split(/[^a-zA-Z0-9_\-\.\+@]+/);
      this.$set(this.website, "permitted_user_emails", emailAddresses);
    },
    accessAdminArea() {
      window.open(this.adminAreaLink);
    },
    copyAdminAreaLinkToClipboard() {
      this.$clipboard(this.adminAreaLink);
      this.$dgToast.show({
        type: "info",
        title: this.$t("dg_suite.consent_management.configuration.admin_access.admin_area_link_copied_title"),
        content: this.$t("dg_suite.consent_management.configuration.admin_access.admin_area_link_copied_description"),
        closeTimeout: 5000,
      });
    },
    applyWebsiteAttributeDefaults() {
      this.website = { ...this.websiteDefaultAttributes, ...this.website };
    },
    async doFetchWebsite() {
      this.isLoading = true;

      try {
        await this.fetchWebsites();
        await this.fetchWebsite(this.$route.params.website_id);

        this.$set(this, "website", { ...this.currentWebsite });
        this.applyWebsiteAttributeDefaults();
      } catch (error) {
        this.backendError = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      this.shouldHighlightAdminButton = false;
      if (this.formSubmitting) return;
      this.wasFormChanged = false;
      this.formSubmitting = true;
      const websiteData = {
        id: this.website.id,
        url: this.website.url,
        permitted_user_emails: this.website.permitted_user_emails.filter(emailAddress => {
          return emailAddress.trim() !== "";
        }),
      };

      try {
        await this.saveWebsite(websiteData);
        this.shouldHighlightAdminButton = true;

        await this.fetchWebsite(this.$route.params.website_id);
      } catch (error) {
        this.backendError = error.message;
      } finally {
        this.formSubmitting = false;
      }
    },
  },
  async created() {
    await this.doFetchWebsite();
  },
};
</script>

<style lang="scss" scoped>
.website-config {
  &__header {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #9aa7b0;
    padding-bottom: 14px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 20px;

    &.sub {
      font-size: 14px;
      color: #0b344b;
      border: none;
      margin-top: 19px;
      margin-bottom: 5px;
      padding-bottom: 0;
    }
  }
  &__body {
    padding-bottom: 30px;

    .row + .row {
      margin-top: 20px;
    }
  }

  .button-group {
    display: flex;

    .dg-ds-button {
      margin-right: 1.25rem;
    }
  }
}
</style>
