<template>
  <DgButton
    v-bind="$attrs"
    @click="$emit('click')"
    :loading="loading"
    :class="['dg-button-highlightable', { [`animation-${animationType}`]: highlight }]"
  >
    <slot />
  </DgButton>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    animationType: {
      type: String,
      default: "load",
      validator: value => ["load", "squish"].includes(value),
    },
  },
};
</script>

<style lang="scss">
.dg-button-highlightable {
  isolation: isolate;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #56c856;
    z-index: -1;
    transform: scale(0, 0);
  }
}

.animation-load {
  animation: shake 300ms 900ms ease-in-out forwards;

  &::before {
    transform-origin: left bottom;
    animation: highlightX 300ms ease-in-out forwards, highlightY 100ms 750ms ease-out forwards,
      fadeAway 2000ms 5000ms linear forwards;
  }
}

.animation-squish {
  --squish: 0.9, 1.1;
  animation: squish 1750ms 750ms ease-out forwards, release 200ms 1750ms cubic-bezier(0.22, 1.9, 0.57, 0.44) forwards;

  &::before {
    content: "";
    transform-origin: left center;
    animation: enterFromLeft 750ms ease-in forwards, exitToRight 75ms 1750ms ease-in forwards;
  }
}

@keyframes squish {
  from {
    transform-origin: right center;
    transform: scale(1, 1);
  }

  to {
    transform-origin: right center;
    transform: scale(var(--squish));
  }
}

@keyframes release {
  from {
    transform-origin: right center;
    transform: scale(var(--squish));
  }

  to {
    transform-origin: right center;
    transform: scale(1, 1);
  }
}

@keyframes enterFromLeft {
  from {
    transform: scale(0, 1);
  }

  to {
    transform: scale(1, 1);
  }
}

@keyframes exitToRight {
  from {
    transform-origin: right center;
    transform: scale(1, 1);
  }

  to {
    transform-origin: right center;
    transform: scale(0, 1);
  }
}

@keyframes highlightX {
  from {
    transform: scale(0, 0.15);
  }
  to {
    transform: scale(1, 0.15);
  }
}

@keyframes highlightY {
  from {
    transform: scale(1, 0.15);
  }
  to {
    transform: scale(1, 1);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-1.5deg);
  }
  50% {
    transform: rotate(1.5deg);
  }
  75% {
    transform: rotate(-1.5deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes fadeAway {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
